/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Icon from './error-robot.svg?react';

const ErrorRobot: React.FC<
  Omit<React.ComponentProps<typeof Icon>, 'focusable' | 'aria-hidden'>
> = (props) => {
  return <Icon {...props} focusable={false} aria-hidden />;
};

export {ErrorRobot};
